<template>
  <div>
    <title>SIM INVENTORY ~ TRACEBILITY FROM PACKING BASED ON BUYER ORDER</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Tracebility From Packing Based On Buyer Order
        <br />
        <h4>The following is a list of data tracebility from packing based on buyer order</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
    
      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
            <div class="box-header with-border">
            <i class="fa fa-list"></i>
              <h3 class="box-title">Tracebility From Packing Based On Buyer Order</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-5">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Batch Code "
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-2">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Batch Code</th>
                      <th>Order Number</th>
                      <th>Prduct Number</th>
                      <th>Process Name</th>
                      <th>Process Date</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Item Qty</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.id }}</td> -->
                      <td>{{ classdata.nomor_produksi }}</td>
                      <td>{{ classdata.nomor_order }}</td>
                      <td>{{ classdata.nomor_produk }}</td>
                      <td>{{ classdata.nama_proses }}</td>
                      <td>{{ classdata.tanggal_proses }}</td>
                      <td>{{ classdata.kode_barang }}</td>
                      <td>{{ classdata.nama_barang }}</td>
                      <td>{{ classdata.qty_barang }}</td>
                      <td>{{ classdata.notes }}</td>
                      <td>
                        <router-link
                          :to="'/viewproduksi-tanggalhasilpacking/' + classdata.ref_id_proses_produksi"
                        >
                          <button
                            title="View Tracebility Packing From Buyer Order"
                            class="btn btn-info"
                          >
                            <i class="fa fa-list"></i>
                          </button>
                        </router-link> &nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="nmsrc">
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
                <div v-else></div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination,
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      list_paged: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSizes: [10, 50, 100, 500],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      const urlApiGETdetail = this.$apiurl + "tracebility_hasil_jadi/getalldataproduk_packing_tanggal_produksi?length="+this.pageSize+"&page="+this.page+"&id_buyer_order=" + this.$route.params.id
      axios
        .get(urlApiGETdetail, { headers: headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged = resp.data.data
          this.count = resp.data.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const paramsst = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "buyerorder" });
        this.loading = true;
        this.fetchData();
      } else {
        const tokenlogin = sessionStorage.getItem("token");
        const urlApiGETdetail = this.$apiurl + "tracebility_hasil_jadi/getalldataproduk_packing?length="+this.pageSize+"&cari="+this.namesearch+"&page="+this.page+"&id_buyer_order="+this.$route.params.id
        axios
            .get(urlApiGETdetail, { paramsst, headers: { Authorization: tokenlogin } })
            .then((resp) => {
                this.list_paged = resp.data.data
                this.count = resp.data.data.datatotalcount;
                this.loading = false;
            })
            .catch((err) => {
                // console.log(err.response);
                swal({
                    icon: "warning",
                    title: "Warning",
                    text: err.response.data,
                }).then(function (isConfirm) {
                    if (isConfirm) {
                    window.location.href = "/";
                    }
                });
            });
      }
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>